define("frontend/models/event", ["exports", "ember-data", "ember-data-storefront/mixins/loadable-model"], function (_exports, _emberData, _loadableModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend(_loadableModel.default, {
    eventName: _emberData.default.attr(),
    city: _emberData.default.attr(),
    country: _emberData.default.attr(),
    eventIdCr: _emberData.default.attr(),
    startDate: _emberData.default.attr(),
    endDate: _emberData.default.attr(),
    brand: _emberData.default.belongsTo('brand'),
    photos: _emberData.default.hasMany('photo')
  });

  _exports.default = _default;
});