define("frontend/routes/forgot-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      token: ''
    },
    model: function model(params) {
      var hasToken = false;

      if (params.token) {
        hasToken = true;
      }

      return {
        token: params.token,
        hasToken: hasToken
      };
    }
  });

  _exports.default = _default;
});