define("frontend/components/event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    filterText: "",
    filteredEvents: Ember.computed('filterText', function () {
      var _this = this;

      var filteredResult = [];
      var events = this.get('model');
      events.forEach(function (event) {
        var itemText = event.get('eventName') + event.brand.get("brandName") + event.get("startDate");

        if (itemText.toLowerCase().includes(_this.filterText.toLowerCase())) {
          filteredResult.push(event);
        }
      });
      return filteredResult;
    }),
    actions: {
      viewPhotos: function viewPhotos(event) {
        this.get('router').transitionTo('event-photos', {
          queryParams: {
            id: event.id
          }
        });
      }
    }
  });

  _exports.default = _default;
});