define("frontend/routes/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import hasEmberVersion from 'ember-test-helpers/has-ember-version';
  var _default = Ember.Route.extend({
    authManager: Ember.inject.service(),
    beforeModel: function beforeModel()
    /* transition */
    {// this.transitionTo('event')
      // if(this.get('authManager').isAuthenticated){
      //     this.transitionTo('event')
      // }
    }
  });

  _exports.default = _default;
});