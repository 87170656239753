define("frontend/services/auth-manager", ["exports", "jquery", "frontend/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    accessToken: Ember.computed('accessToken', function () {
      try {
        return JSON.parse(localStorage.accessToken);
      } catch (e) {
        return false;
      }
    }),
    authenticate: function authenticate(login, password) {
      var _this = this;

      return _jquery.default.ajax({
        method: "POST",
        url: _environment.default.APP.API_HOST + "/api/api-token-auth/",
        data: {
          username: login,
          password: password
        }
      }).then(function (result) {
        _this.set('accessToken', result.token);

        localStorage.setItem('accessToken', JSON.stringify(result.token));
      });
    },
    invalidate: function invalidate() {
      this.set('accessToken', null);
      localStorage.clear();
    },
    isAuthenticated: Ember.computed.bool('accessToken')
  });

  _exports.default = _default;
});