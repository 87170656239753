define("frontend/components/nav-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    authManager: Ember.inject.service(),
    isAuthenticated: Ember.computed('authManager.isAuthenticated', function () {
      return this.authManager.get("isAuthenticated");
    })
  });

  _exports.default = _default;
});