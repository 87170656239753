define("frontend/templates/components/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xQBm7fsF",
    "block": "{\"symbols\":[],\"statements\":[[0,\"Successfully Logged in!\\n\"],[7,\"p\",true],[8],[1,[24,[\"authManager\",\"accessToken\"]],false],[9],[0,\"\\n\"],[7,\"p\",true],[8],[0,\"Authenticed: \"],[1,[24,[\"authManager\",\"isAuthenticated\"]],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/home.hbs"
    }
  });

  _exports.default = _default;
});