define("frontend/components/account-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flashMsgSuccess: false,
    flashMsgError: false,
    msg: "",
    actions: {
      updateData: function updateData() {
        var _this = this;

        this.set('flashMsgSuccess', false);
        this.set('flashMsgError', false);
        this.get('user').save().then(function (result) {
          _this.set('flashMsgSuccess', true);

          _this.set('msg', "Successfully updated account settings!");
        }).catch(function (err) {
          _this.set('flashMsgError', true);

          _this.set('msg', "Failed to update account settings!");
        });
      }
    }
  });

  _exports.default = _default;
});