define("frontend/routes/logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    authManager: Ember.inject.service(),
    beforeModel: function beforeModel()
    /* transition */
    {
      // this.transitionTo('event')
      // if(!this.get('authManager').isAuthenticated){
      //     this.transitionTo('login')
      // }
      this.get('authManager').invalidate();
      this.transitionTo('login');
    }
  });

  _exports.default = _default;
});