define("frontend/models/brand", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    brandName: _emberData.default.attr(),
    brandIdCr: _emberData.default.attr(),
    producerIdCr: _emberData.default.attr(),
    producer: _emberData.default.belongsTo('producer'),
    events: _emberData.default.hasMany('event'),
    brands: _emberData.default.hasMany('brand'),
    users: _emberData.default.hasMany('user')
  });

  _exports.default = _default;
});