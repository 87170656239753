define("frontend/templates/account-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7HOuaas4",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"account-settings\",null,[[\"user\"],[[24,[\"model\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/account-settings.hbs"
    }
  });

  _exports.default = _default;
});