define("frontend/adapters/application", ["exports", "ember-data", "frontend/config/environment", "ember-data/adapters/rest", "ember-inflector"], function (_exports, _emberData, _environment, _rest, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import DRFAdapter from './drf';
  var _default = _emberData.default.RESTAdapter.extend({
    namespace: _environment.default.APP.API_NAMESPACE,
    host: _environment.default.APP.API_HOST,
    authManager: Ember.inject.service(),
    addTrailingSlashes: true,

    /**
    * Determine the pathname for a given type.
    *
    * @method pathForType
    * @param {String} type
    * @return {String} path
    */
    pathForType: function pathForType(type) {
      var dasherized = Ember.String.dasherize(type);
      return (0, _emberInflector.pluralize)(dasherized);
    },

    /**
    Builds a URL for a given model name and optional ID.
    By default, it pluralizes the type's name (for example, 'post'
    becomes 'posts' and 'person' becomes 'people').
    If an ID is specified, it adds the ID to the path generated
    for the type, separated by a `/`.
    If the adapter has the property `addTrailingSlashes` set to
    true, a trailing slash will be appended to the result.
    @method buildURL
    @param {String} modelName
    @param {(String|Array|Object)} id single id or array of ids or query
    @param {(DS.Snapshot|Array)} snapshot single snapshot or array of snapshots
    @param {String} requestType
    @param {Object} query object of query parameters to send for query requests.
    @return {String} url
    */
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = this._super(modelName, id, snapshot, requestType, query);

      if (this.get('addTrailingSlashes')) {
        if (url.charAt(url.length - 1) !== '/') {
          url += '/';
        }
      }

      return url;
    },

    /**
       Convert validation errors to a JSON API object.
       Non-field errors are converted to an object that points at /data.  Field-
       specific errors are converted to an object that points at the respective
       attribute.  Nested field-specific errors are converted to an object that
       include a slash-delimited pointer to the nested attribute.
       NOTE: Because JSON API does not technically support nested resource objects
             at this time, any nested errors are literally "in name" only.  The
             error object will be attached to the parent resource and the nested
             object's isValid property will continue to be true.
       @method _drfToJsonAPIValidationErrors
       @param {Object} payload
       @param {String} keyPrefix Used to recursively process nested errors
       @return {Array} A list of JSON API compliant error objects
     */
    _drfToJsonAPIValidationErrors: function _drfToJsonAPIValidationErrors(payload) {
      var _this = this;

      var keyPrefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var out = [];
      payload = this._formatPayload(payload);

      var _loop = function _loop(key) {
        /*jshint loopfunc: true */
        if (payload.hasOwnProperty(key)) {
          if (Ember.isArray(payload[key])) {
            payload[key].forEach(function (error) {
              if (key === _this.get('nonFieldErrorsKey')) {
                out.push({
                  source: {
                    pointer: '/data'
                  },
                  detail: error,
                  title: 'Validation Error'
                });
              } else {
                out.push({
                  source: {
                    pointer: "/data/attributes/".concat(keyPrefix).concat(key)
                  },
                  detail: error,
                  title: 'Invalid Attribute'
                });
              }
            });
          } else {
            out = out.concat(_this._drfToJsonAPIValidationErrors(payload[key], "".concat(keyPrefix).concat(key, "/")));
          }
        }
      };

      for (var key in payload) {
        _loop(key);
      }

      return out;
    },

    /**
     * This is used by RESTAdapter._drfToJsonAPIValidationErrors.
     *
     * Map string values to arrays because improperly formatted payloads cause
     * a maximum call stack size exceeded error
     *
     * @method _formatPayload
     * @param {Object} payload
     * @return {Object} payload
     */
    _formatPayload: function _formatPayload(payload) {
      for (var key in payload) {
        if (payload.hasOwnProperty(key)) {
          if (typeof payload[key] === 'string') {
            payload[key] = [payload[key]];
          }
        }
      }

      return payload;
    },

    /**
     * This is used by RESTAdapter.groupRecordsForFindMany.
     *
     * The original implementation does not handle trailing slashes well.
     * Additionally, it is a complex stripping of the id from the URL,
     * which can be dramatically simplified by just returning the base
     * URL for the type.
     *
     * @method _stripIDFromURL
     * @param {DS.Store} store
     * @param {DS.Snapshot} snapshot
     * @return {String} url
     */
    _stripIDFromURL: function _stripIDFromURL(store, snapshot) {
      return this.buildURL(snapshot.modelName);
    },
    // coalesceFindRequests: true,
    headers: Ember.computed('authManager.accessToken', function () {
      return {
        "Authorization": "Token ".concat(this.get("authManager.accessToken"))
      };
    }),
    init: function init() {
      this._super.apply(this, arguments); // this.set('headers', {
      //   "Authorization": `Token ${this.get("authManager.accessToken")}`,
      //   "Accept": "application/json"
      // });

    }
  });

  _exports.default = _default;
});