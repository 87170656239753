define("frontend/templates/event-photos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uWRDKqW4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n\"],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"\\n                \"],[7,\"p\",true],[8],[7,\"strong\",true],[8],[0,\"Event: \"],[9],[1,[24,[\"model\",\"event\",\"eventName\"]],false],[9],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"\\n                \"],[7,\"p\",true],[8],[7,\"strong\",true],[8],[0,\"Brand: \"],[9],[1,[24,[\"model\",\"event\",\"brand\",\"brandName\"]],false],[9],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"\\n                \"],[7,\"p\",true],[8],[7,\"strong\",true],[8],[0,\"Date: \"],[9],[1,[24,[\"model\",\"event\",\"startDate\"]],false],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"],[1,[28,\"event-photos\",null,[[\"model\",\"params\"],[[24,[\"model\"]],[24,[\"params\"]]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/event-photos.hbs"
    }
  });

  _exports.default = _default;
});