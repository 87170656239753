define("frontend/models/photo", ["exports", "ember-data", "ember-data-storefront/mixins/loadable-model"], function (_exports, _emberData, _loadableModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend(_loadableModel.default, {
    image: _emberData.default.attr(),
    thumbnail: _emberData.default.attr(),
    teamName: _emberData.default.attr(),
    divisionName: _emberData.default.attr(),
    photographer: _emberData.default.attr(),
    event: _emberData.default.belongsTo('event'),
    rating: _emberData.default.attr(),
    createdAt: _emberData.default.attr('date'),
    tags: _emberData.default.attr(),
    videoclipIdCr: _emberData.default.attr(),
    photographerName: _emberData.default.attr()
  });

  _exports.default = _default;
});