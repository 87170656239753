define("frontend/app", ["exports", "frontend/resolver", "ember-load-initializers", "frontend/config/environment"], function (_exports, _resolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Ember.onerror = function (error) {
  //   console.log('Ember.onerror handler', error.message);
  //   //this.router.transitionTo("login");
  //   //uncomment me in prod
  //   // window.location.href = "/login";
  // };
  // Ember.RSVP.on('error', function (error) {
  //   console.log('Ember.RSVP error handler', error);
  // });
  // Ember.Logger.error = function (message, cause, stack) {
  //   console.log('Ember.Logger.error handler', message, cause, stack);
  // };
  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});