define("frontend/templates/event-photos-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F0UOtgR+",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n    \"],[7,\"center\",true],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"spinner-border\"],[10,\"style\",\"width: 20rem; height: 20rem;\"],[10,\"role\",\"status\"],[8],[0,\"\\n            \"],[7,\"span\",true],[10,\"class\",\"sr-only\"],[8],[0,\"Loading...\"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[0,\"\\n        \"],[7,\"h2\",true],[8],[0,\"Currently loading event photos\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/event-photos-loading.hbs"
    }
  });

  _exports.default = _default;
});