define("frontend/components/change-password", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    authManager: Ember.inject.service(),
    oldPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
    flashMsgSuccess: false,
    flashMsgError: false,
    msg: "",
    actions: {
      changePassword: function changePassword() {
        this.set("flashMsgSuccess", false);
        this.set("flashMsgError", false);
        var user = this.get('user');
        var component = this;
        var token = this.authManager.get('accessToken');
        var oldPassword = this.get("oldPassword");
        var newPassword = this.get("newPassword");

        if (this.newPassword === this.newPasswordConfirm && this.newPassword != "") {
          //console.log(user.id);
          //console.log("changingpassword");
          $.ajax({
            method: "POST",
            url: _environment.default.APP.API_HOST + "/" + _environment.default.APP.API_NAMESPACE + '/users/' + String(user.id) + '/change-password/',
            data: {
              "old_password": oldPassword,
              "new_password": newPassword
            },
            headers: {
              "Authorization": "Token " + token
            }
          }).then(function (result) {
            component.set("flashMsgSuccess", true);
            component.set("msg", "Successfully changed password!");
            component.set("oldPassword", "");
            component.set("newPassword", "");
            component.set("newPasswordConfirm", "");
          }).catch(function (err) {
            component.set("flashMsgError", true);
            component.set("msg", "Failed to change password, your old password may be incorrect!");
            component.set("oldPassword", "");
            component.set("newPassword", "");
            component.set("newPasswordConfirm", ""); //console.log(err);
          });
        } else {
          this.set("flashMsgError", true);
          this.set("msg", "Please be sure you have supplied all of the required information!");
        }
      }
    }
  });

  _exports.default = _default;
});