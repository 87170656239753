define("frontend/router", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login');
    this.route('home');
    this.route('event');
    this.route('event-photos');
    this.route('event', {
      path: '/'
    }, function () {});
    this.route('logout');
    this.route('account-settings');
    this.route('forgot-password');
    this.route('loading-photos');
  });
  var _default = Router;
  _exports.default = _default;
});