define("frontend/routes/event-photos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      id: '',
      page: {
        refreshModel: true
      },
      min_rating: {
        refreshModel: true
      },
      showFilters: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      },
      filterByDate: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var searchValue = "";
      var dateValue = "";

      if (params.search) {
        searchValue = params.search;
      }

      if (params.filterByDate) {
        dateValue = params.filterByDate;
      }

      if (params.showFilters) {
        if (params.min_rating > 0) {
          return Ember.RSVP.hash({
            event: this.store.findRecord('event', params.id),
            photos: this.store.query('photo', {
              event: params.id,
              page: params.page,
              search: searchValue + ' ' + dateValue,
              min_rating: params.min_rating
            }),
            events: this.store.findAll('event'),
            min_rating: params.min_rating,
            searchFilter: params.search,
            showFilters: true,
            filterByDate: params.filterByDate
          });
        } else {
          return Ember.RSVP.hash({
            event: this.store.findRecord('event', params.id),
            photos: this.store.query('photo', {
              event: params.id,
              page: params.page,
              search: searchValue + ' ' + dateValue
            }),
            events: this.store.findAll('event'),
            min_rating: 0,
            searchFilter: params.search,
            showFilters: true,
            filterByDate: params.filterByDate
          });
        }
      } else {
        return Ember.RSVP.hash({
          event: this.store.findRecord('event', params.id),
          photos: this.store.query('photo', {
            event: params.id,
            page: params.page
          }),
          events: this.store.findAll('event'),
          showFilters: false,
          min_rating: 0,
          filterByDate: 'all'
        });
      }
    }
  });

  _exports.default = _default;
});