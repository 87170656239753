define("frontend/components/forgot-password", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    email: "",
    flashMsgSuccess: false,
    flashMsgError: false,
    msg: "",
    newPassword: "",
    newPasswordConfirm: "",
    actions: {
      requestPassword: function requestPassword() {
        this.set("flashMsgSuccess", false);
        this.set("flashMsgError", false);
        var email = this.get('email');
        var component = this;

        if (email != "") {
          //console.log(user.id);
          //console.log("changingpassword");
          $.ajax({
            method: "POST",
            url: _environment.default.APP.API_HOST + "/" + _environment.default.APP.API_NAMESPACE + '/password_reset/',
            data: {
              "email": email
            },
            headers: {//"Authorization": "Token " + token
            }
          }).then(function (result) {
            component.set("flashMsgSuccess", true);
            component.set("msg", "Password request submitted! Please check your email!");
          }).catch(function (err) {
            component.set("flashMsgError", true);
            component.set("msg", "Password request failed, please try another email address."); //console.log(err);
          });
        } else {
          this.set("flashMsgError", true);
          this.set("msg", "Please be sure you have supplied all of the required information!");
        }
      },
      changePassword: function changePassword() {
        this.set("flashMsgSuccess", false);
        this.set("flashMsgError", false);
        var email = this.get('email');
        var component = this;

        if (this.newPassword === this.newPasswordConfirm && this.newPassword != "") {
          //console.log(user.id);
          //console.log("changingpassword");
          $.ajax({
            method: "POST",
            url: _environment.default.APP.API_HOST + "/" + _environment.default.APP.API_NAMESPACE + '/password_reset/confirm/',
            data: JSON.stringify({
              token: this.get("model").token,
              password: this.get("newPassword")
            }),
            contentType: "application/json; charset=utf-8",
            processData: false,
            dataType: "json"
          }).then(function (result) {
            component.set("flashMsgSuccess", true);
            component.set("msg", "Password has been successfully changed, please continue to login!");
          }).catch(function (err) {
            component.set("flashMsgError", true);

            if (err.status == 404) {
              component.set("msg", "This token has expired or already been used.");
            } else {
              component.set("msg", err.responseText);
            }

            console.log(err);
          });
        } else {
          this.set("flashMsgError", true);
          this.set("msg", "Please be sure you have supplied all of the required information and that your passwords match!");
        }
      }
    }
  });

  _exports.default = _default;
});