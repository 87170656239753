define("frontend/routes/account-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.findAll("user").then(function (users) {
        return users.get("firstObject");
      });
    }
  });

  _exports.default = _default;
});