define("frontend/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    authManager: Ember.inject.service(),
    year: Ember.computed(function () {
      new Date().getFullYear();
    }),
    afterModel: function afterModel()
    /* transition */
    {// this.transitionTo('event')
      //console.log(this.get("authManager").isAuthenticated);
      // if(!this.get('authManager').isAuthenticated){
      //     this.transitionTo('login')
      //     //window.location.href = "/login";
      // }
    },
    model: function model() {
      return {
        year: new Date().getFullYear()
      };
    },
    actions: {
      error: function error(_error, transition) {
        if (_error.errors[0].status === "401") {
          this.transitionTo('login');
        } //Add additional error states here.

      }
    }
  });

  _exports.default = _default;
});