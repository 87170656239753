define("frontend/components/login-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    authManager: Ember.inject.service(),
    router: Ember.inject.service(),
    err: null,
    errStatus: Ember.computed(function () {
      return this.err;
    }),
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var self = this;

        var _this$getProperties = this.getProperties('login', 'password'),
            login = _this$getProperties.login,
            password = _this$getProperties.password;

        this.get('authManager').authenticate(login, password).then(function () {
          //component.transitionToRoute('home');
          _this.get('router').transitionTo('event'); // Ember.getOwner(this).lookup('router:main').transitionTo('event');
          //alert('Success! Click the top link!');

        }, function (err) {
          _this.set('err', true); //alert('Error obtaining token: ' + err.responseText);

        });
      }
    }
  });

  _exports.default = _default;
});