define("frontend/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    firstName: _emberData.default.attr(),
    lastName: _emberData.default.attr(),
    email: _emberData.default.attr(),
    title: _emberData.default.attr(),
    affiliation: _emberData.default.attr()
  });

  _exports.default = _default;
});